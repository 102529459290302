import zIndex from "@mui/material/styles/zIndex";
import React from "react";

const WhatsAppButton = () => {
  const phoneNumber = "7036388881"; 
  const message = "Hii, Enquire from HashPro Academy website"; 
  const handleClick = () => {
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(url, "_blank");
  };

  return (
    <div
      style={styles.button}
      onClick={handleClick}
      aria-label="Chat with us on WhatsApp"
    >
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
        alt="WhatsApp Icon"
        style={styles.icon}
      />
    </div>
  );
};

const styles = {
  button: {
    position: "fixed",

    bottom: "5px",
    right: "10px",

    cursor: "pointer",

    zIndex: "934278934276872346767",
  },
  icon: {
    width: "50px",
    height: "50px",
  },
};

export default WhatsAppButton;
