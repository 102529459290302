import { useAuth } from "../../Providers/AuthProvider";
import PaymentUtils from "./PaymentUtils";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useEffect, useState } from "react";

export default function Payment() {
  const [userPrevData, setUserPrevData] = useState({});
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser.email) {
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}/user/get-payment-history`, {
          email: currentUser.email,
        })
        .then((response) => {
          console.log(response.data);
          setUserPrevData(response.data[0] || {});
        })
        .catch((error) => {
          console.error("Error fetching payment history:", error);
        });
    }
  }, [currentUser.email]);

  const formik = useFormik({
    initialValues: {
      tid: "",
      merchant_id: 3274726,
      order_id: PaymentUtils.generate_order_id(),
      currency: "INR",
      redirect_url: `${process.env.REACT_APP_SERVER_URL}/payments/ccavResponseHandler`,
      cancel_url: `${process.env.REACT_APP_SERVER_URL}/payments/ccavResponseHandler`,
      language: "EN",
      billing_name: userPrevData?.billing_name || "",
      billing_tel: userPrevData?.billing_tel || "",
      billing_email: currentUser.email || "",
      amount: "",
      billing_course: userPrevData?.billingCourse || "",
      billing_payment_type: "",
      termsAccepted: false,
    },
    validationSchema: Yup.object({
      billing_name: Yup.string().required("Required"),
      billing_tel: Yup.string()
        .matches(/^\d{10}$/, "Please enter a valid 10-digit mobile number")
        .required("Required"),
      billing_email: Yup.string()
        .email("Invalid email address")
        .required("Required"),
      amount: Yup.number()
        .typeError("Amount must be a number")
        .min(11, "Amount must be greater than 11")
        .required("Required"),
      billing_course: Yup.string().required("Required"),
      billing_payment_type: Yup.string().required("Required"),
      termsAccepted: Yup.boolean()
        .oneOf([true], "Please Agree to The Terms And Conditions")
        .required("Required"),
    }),
    onSubmit: (values) => {
      document.customerData.submit();
    },
  });

  useEffect(() => {
    formik.setValues((prevValues) => ({
      ...prevValues,
      billing_name: userPrevData?.billing_name || "",
      billing_tel: userPrevData?.billing_tel || "",
      billing_course: userPrevData?.billingCourse || "",
    }));
  }, [userPrevData]);

  return (
    <div className="pt-10 hilight">
      <section className="fee-form-container">
        <form
          method="post"
          name="customerData"
          action={`${process.env.REACT_APP_SERVER_URL}/payments/ccavRequestHandler`}
          onSubmit={formik.handleSubmit}
        >
          <div className="form-group">
            <input
              type="text"
              hidden
              name="tid"
              id="tid"
              readOnly
              value={formik.values.tid}
            />
            <input
              type="text"
              hidden
              name="merchant_id"
              value={formik.values.merchant_id}
            />
            <input
              type="text"
              hidden
              name="order_id"
              id="order_id"
              value={formik.values.order_id}
            />
            <input
              type="text"
              hidden
              name="currency"
              value={formik.values.currency}
            />
            <input
              type="text"
              hidden
              name="redirect_url"
              value={formik.values.redirect_url}
            />
            <input
              type="text"
              hidden
              name="cancel_url"
              value={formik.values.cancel_url}
            />
            <input
              type="text"
              hidden
              name="language"
              value={formik.values.language}
            />
          </div>

          <div className="form-group">
            <label className="details-label" htmlFor="billing_name">
              Full Name :
            </label>
            <input
              type="text"
              name="billing_name"
              id="billing_name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.billing_name}
              required
            />
            {formik.touched.billing_name && formik.errors.billing_name ? (
              <div className="error-pay">{formik.errors.billing_name}</div>
            ) : null}
          </div>

          <div className="form-group">
            <label className="details-label" htmlFor="billing_tel">
              Phone No :
            </label>
            <input
              type="text"
              name="billing_tel"
              id="billing_tel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.billing_tel}
              required
            />
            {formik.touched.billing_tel && formik.errors.billing_tel ? (
              <div className="error-pay">{formik.errors.billing_tel}</div>
            ) : null}
          </div>

          <div className="form-group">
            <label className="details-label" htmlFor="billing_email">
              Email Id :
            </label>
            <input
              type="text"
              name="billing_email"
              id="billing_email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.billing_email}
              required
              readOnly
            />
            {formik.touched.billing_email && formik.errors.billing_email ? (
              <div className="error-pay">{formik.errors.billing_email}</div>
            ) : null}
          </div>

          <div className="form-group">
            <label className="details-label" htmlFor="amount">
              Amount :
            </label>
            <input
              type="text"
              name="amount"
              id="amount"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.amount}
              required
            />
            {formik.touched.amount && formik.errors.amount ? (
              <div className="error-pay">{formik.errors.amount}</div>
            ) : null}
          </div>

          <div className="form-group">
            <label className="details-label" htmlFor="billing_course">
              Choose your Course :
            </label>
            <select
              name="billing_course"
              id="billing_course"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.billing_course}
              style={{ color: "black" }}
              required
            >
              <option value="" label="Select a course" />
              <option
                style={{ color: "black", fontWeight: "600" }}
                value="DMX"
                label="DMX (Digital Marketing Experiential )"
              />
              <option
                style={{ color: "black", fontWeight: "600" }}
                value="AOI"
                label="AOI (Art of Influence)"
              />
            </select>
            {formik.touched.billing_course && formik.errors.billing_course ? (
              <div className="error-pay">{formik.errors.billing_course}</div>
            ) : null}
          </div>

          <div className="form-group">
            <label className="details-label" htmlFor="billing_payment_type">
              Choose Payment Type :
            </label>
            <select
              name="billing_payment_type"
              id="billing_payment_type"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.billing_payment_type}
              required
              style={{ color: "black" }}
            >
              <option value="" label="Select payment type" />
              <option
                style={{ color: "black", fontWeight: "600" }}
                value="Admission fee"
                label="Admission Fee"
              />
              <option
                style={{ color: "black", fontWeight: "600" }}
                value="Fullpayment"
                label="Full Payment"
              />
              <option
                style={{ color: "black", fontWeight: "600" }}
                value="Installment"
                label="Installment"
              />
            </select>
            {formik.touched.billing_payment_type &&
            formik.errors.billing_payment_type ? (
              <div className="error-pay">
                {formik.errors.billing_payment_type}
              </div>
            ) : null}
          </div>

          <div className="form-group">
            <label
              className="details-label"
              htmlFor="termsAccepted"
              id="termsacc"
            >
              <input
                type="checkbox"
                name="termsAccepted"
                id="termsAccepted"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                checked={formik.values.termsAccepted}
              />
              <span id="mobile-terms">
                {" "}
                I confirm that I have read and accept the{" "}
                <a
                  href="https://www.hashproacademy.com/terms-and-conditions"
                  rel="noopener noreferrer"
                >
                  Terms and Conditions
                </a>
              </span>
            </label>
            {formik.touched.termsAccepted && formik.errors.termsAccepted ? (
              <div className="error-pay">{formik.errors.termsAccepted}</div>
            ) : null}
          </div>

          <div className="form-group">
            <button type="submit">Proceed to payment</button>
          </div>
        </form>
      </section>
    </div>
  );
}


