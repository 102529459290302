import React from "react";
import "./InfiniteScrollCarousel.css";

const tools = [
  {
    id: "ga-f",
    name: "Google Analytics 4",
    src: "/pages/dmx/images/tools/googleanalytics.png",
  },
  { id: "ga", name: "Google Ads", src: "/pages/dmx/images/tools/ga.png" },
  {
    id: "gtm",
    name: "Google Tag Manager",
    src: "/pages/dmx/images/tools/gtm.webp",
  },
  {
    id: "gmb",
    name: "Google My Business",
    src: "/pages/dmx/images/tools/gmb.png",
  },
  { id: "meta", name: "Meta", src: "/pages/dmx/images/tools/meta.png" },
  { id: "wp", name: "WordPress", src: "/pages/dmx/images/tools/wp.png" },
  {
    id: "mailchimp",
    name: "MailChimp",
    src: "/pages/dmx/images/tools/mailchimp2.png",
  },
  { id: "canva", name: "Canva", src: "/pages/dmx/images/tools/canva2.png" },
  { id: "gpt", name: "Chat GPT", src: "/pages/dmx/images/tools/gpt.png" },
  { id: "sem", name: "Semrush", src: "/pages/dmx/images/tools/semrush.png" },
  { id: "divi", name: "Divi", src: "/pages/dmx/images/tools/divi.png" },
  {
    id: "hubspot",
    name: "HubSpot",
    src: "/pages/dmx/images/tools/hubspot.webp",
  },
];

const InfiniteScrollCarousel = () => {
  return (
    <div className="containertoolsnew mx-auto px-4 sm:px-12 py-8">
      {/* Carousel 1 */}
      <div className="slidertooldmx">
        <div className="slide-track">
          {[...tools.slice(0, 6), ...tools.slice(0, 6)].map((tool, index) => (
            <div key={index} className="slide">
              <div className={`tool ${tool.id}`}>
                <div className="center-img">
                  <img src={tool.src} alt={tool.name} />
                </div>
                <p>{tool.name}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Carousel 2 */}
      <div className="slidertooldmx">
        <div className="slide-track2">
          {[...tools.slice(6, 12), ...tools.slice(6, 12)].map((tool, index) => (
            <div key={index} className="slide2tool">
              <div className={`tool ${tool.id}`}>
                <div className="center-img">
                  <img src={tool.src} alt={tool.name} />
                </div>
                <p>{tool.name}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InfiniteScrollCarousel;
