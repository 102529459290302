import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import CurrentWeek from "./CurrentWeek";

const SalesForm = () => {
  const [salesData] = useState([
    { name: "Sandeep", email: "sandeep@gmail.com", salesCount: "" },
    { name: "Pavaan", email: "pavaan@example.com", salesCount: "" },
    { name: "Prashanth", email: "prashanth@example.com", salesCount: "" },
    { name: "Sreekanth", email: "sreekanth@example.com", salesCount: "" },
    { name: "Rakshita", email: "rakshita@example.com", salesCount: "" },
    { name: "Deekhita", email: "deekhita@example.com", salesCount: "" },
  ]);

  const [date] = useState(new Date().toISOString().split("T")[0]);

  const validationSchema = Yup.object().shape({
    salesData: Yup.array().of(
      Yup.object().shape({
        salesCount: Yup.number()
          .typeError("Sales count must be a number")
          .integer("Sales count must be an integer")
          .min(0, "Sales count cannot be negative")
          .required("Sales count is required"),
      })
    ),
  });
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/leadboard/add-sales-count-daily`,
        {
          salesData: values.salesData, 
          date,
        }
      );
      alert("Sales data submitted successfully.");
      resetForm();
    } catch (error) {
      console.error("Error submitting sales data:", error);
      alert("Error submitting sales data. Please try again later.");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <Formik
      initialValues={{ salesData }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, isSubmitting }) => (
        <Form className="pt-[100px] space-y-4">
          <div>
            <label>
              Date:
              <input
                type="date"
                value={date}
                disabled
                className="ml-2 p-2 border rounded"
              />
            </label>
          </div>

          {values.salesData.map((member, index) => (
            <div key={member.email} className="flex items-center space-x-4">
              <label className="font-medium">
                {member.name} ({member.email})
              </label>
              <Field
                type="number"
                name={`salesData[${index}].salesCount`}
                placeholder="Sales Count"
                className="p-2 border rounded"
              />
              <ErrorMessage
                name={`salesData[${index}].salesCount`}
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
          ))}

          <button
            type="submit"
            disabled={isSubmitting}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-gray-400"
          >
            {isSubmitting ? "Submitting..." : "Submit Sales Data"}
          </button>
        </Form>
      )}
    </Formik>

    <CurrentWeek/>
    </div>
  );
};

export default SalesForm;
