import React from 'react';
import moment from 'moment';

function CurrentWeek() {
  const currentWeek = moment().isoWeek();
  const startOfWeek = moment().startOf('isoWeek').format('YYYY-MM-DD');
  const endOfWeek = moment().endOf('isoWeek').format('YYYY-MM-DD');

  const currentDateWithTime = moment().format('YYYY-MM-DD HH:mm:ss');

  return (
    <div>
      <h2>Current Week</h2>
      <p>Week Number: {currentWeek}</p>
      <p>Start Date: {startOfWeek}</p>
      <p>End Date: {endOfWeek}</p>
      <p>Current Date and Time: {currentDateWithTime}</p>
    </div>
  );
}

export default CurrentWeek;
