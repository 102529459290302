import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import CurrentWeek from "./CurrentWeek";

const SalesDataComponent = () => {
  const [salesData, setSalesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSalesData = async () => {
      setLoading(true); // Set loading state
      try {
        const currentMonth = moment().format("MMMM");

        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/leadboard/get-monthsales-details`,
          { month: currentMonth }
        );

        const sortedData = response.data.salesData.salesData.sort(
          (a, b) => b.salesCount - a.salesCount
        );
        setSalesData(sortedData);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching sales data:", err);
        setError("Failed to fetch sales data");
        setLoading(false);
      }
    };

    fetchSalesData();
  }, []);

  if (loading) {
    return <div className="pt-[300px]">Loading...</div>;
  }

  if (error) {
    return <div className="pt-[300px]">{error}</div>;
  }

  return (
    <div className="pt-[300px]">
      <h3>Sales Data for {moment().format("MMMM YYYY")}</h3>
      <ul>
        {salesData.length > 0 ? (
          salesData.map((item, index) => (
            <li key={index}>
              {item.name}: {item.salesCount} sales
            </li>
          ))
        ) : (
          <li>No sales data available</li>
        )}
      </ul>

<CurrentWeek/>
    </div>
  );
};

export default SalesDataComponent;
