import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Navigation } from "swiper/modules";
import "./slider.css";
import student1 from "../../studenttest/2.jpg";
import student2 from "../../studenttest/1.jpg";
import { FaPlay } from "react-icons/fa";

const WhyChooseSlider = () => {
  return (
    <div className="relative mx-width-[100px] px-[40px]">
      <Swiper
        modules={[Navigation]}
        navigation={{
          nextEl: ".custom-swiper-button-next",
          prevEl: ".custom-swiper-button-prev",
        }}
        spaceBetween={60}
        breakpoints={{
          768: { slidesPerView: 4 },
          0: { slidesPerView: 1 },
        }}
      >
        <div className="whychoosesledediv">
          <SwiperSlide className="slideparent">
            <div className="slide-content">
              <a
                className="instagram-video-anchor"
                href="https://www.instagram.com/p/DCHF1m-yGEX/?hl=en"
                target="_blank" rel="noreferrer"
              >
                <div id="insta-vid-2" className="instagram-video-container">
                  <img loading="lazy" className="thumbnail" src={student1} alt="student-thumbnail-hashpro" />
                  <div className="whychooseinstabtn">
                    <FaPlay />
                  </div>
                </div>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slideparent">
            <div className="slide-content">
            <a
                className="instagram-video-anchor"
                href="https://www.instagram.com/p/DB_ZoT-sTzf/?hl=en"
                target="_blank" rel="noreferrer"
              >
                <div id="insta-vid-2" className="instagram-video-container">
                  <img loading="lazy" className="thumbnail" src={student2} alt="student-thumbnail-hashpro" />
                  <div className="whychooseinstabtn">
                    <FaPlay />
                  </div>
                </div>
              </a>
            </div>
          </SwiperSlide>
          {/* <SwiperSlide className="slideparent">
            <div className="slide-content">
              <a
                className="instagram-video-anchor"
                href="https://www.instagram.com/p/DCHF1m-yGEX/?hl=en"
                target="_blank" rel="noreferrer"
              >
                <div id="insta-vid-2" className="instagram-video-container">
                  <img loading="lazy" className="thumbnail" src={student1} alt="student-thumbnail-hashpro" />
                  <div className="whychooseinstabtn">
                    <FaPlay />
                  </div>
                </div>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slideparent">
            <div className="slide-content">
            <a
                className="instagram-video-anchor"
                href="https://www.instagram.com/p/DB_ZoT-sTzf/?hl=en"
                target="_blank" rel="noreferrer"
              >
                <div id="insta-vid-2" className="instagram-video-container">
                  <img loading="lazy" className="thumbnail" src={student2}  alt="student-thumbnail-hashpro"/>
                  <div className="whychooseinstabtn">
                    <FaPlay />
                  </div>
                </div>
              </a>
            </div>
          </SwiperSlide> */}
          
        </div>
      </Swiper>

      {/* Custom Navigation Buttons with React Icons */}
      <div className="custom-swiper-button-prev">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 24 24"
          fill="#000"
          stroke="#fff"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="lucide lucide-circle-arrow-left"
        >
          <circle cx="12" cy="12" r="10" />
          <path d="M16 12H8" />
          <path d="m12 8-4 4 4 4" />
        </svg>
      </div>
      <div className="custom-swiper-button-next">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 24 24"
          fill="#000"
          stroke="#fff"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="lucide lucide-circle-arrow-right"
        >
          <circle cx="12" cy="12" r="10" />
          <path d="M8 12h8" />
          <path d="m12 16 4-4-4-4" />
        </svg>
      </div>
    </div>
  );
};

export default WhyChooseSlider;
