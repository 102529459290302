import React, { useEffect } from 'react';
import confetti from 'canvas-confetti';
import './congratsrevel.css'

const Congratulations = () => {
  useEffect(() => {
    const end = Date.now() + 5 * 1000;
    const colors = ['#fff', '#F3CD00'];

    const frame = () => {
      confetti({
        particleCount: 5,
        angle: 45,
        spread: 100,
        origin: { x: 0 },
        colors: colors,
      });
      confetti({
        particleCount: 5,
        angle: 150,
        spread: 100,
        origin: { x: 1 },
        colors: colors,
      });

      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    };

    frame(); // Start the animation

    return () => {
      confetti.reset(); // Cleanup when component unmounts if needed
    };
  }, []);

  return (
    <div className="congratsleads" style={{ textAlign: 'center' }}>
      <h1>Congratulations!</h1>
    </div>
  );
};

export default Congratulations;
